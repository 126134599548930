import { MemberToAttendModel, EventModel, MemberCheckedInModel } from '@/events/models';

export interface EventsState {
    fetchedEvents: EventModel[];
    filteredEvents: EventModel[];
    selectedEvent: EventModel | undefined;
    membersToAttend: MemberToAttendModel[];
    membersCheckedIn: MemberCheckedInModel[];
}

export const initialEventsState: EventsState = {
    fetchedEvents: [],
    filteredEvents: [],
    selectedEvent: undefined,
    membersToAttend: [],
    membersCheckedIn: [],
};

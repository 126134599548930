import React, { FunctionComponent } from 'react';
import Logo from '@/assets/images/medium-logo.png';
import Styles from './Styles.module.scss';

type Props = {};

export const LogoSpinner: FunctionComponent<Props> = (props) => {
    return (
        <div className={Styles.Spin}>
            <img src={Logo} alt={'logo'} />
        </div>
    );
};

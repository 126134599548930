import React, { FunctionComponent } from 'react';
import { LogoClient } from './logo-client';

type Props = {};

export const Navbar: FunctionComponent<Props> = (props) => {
    return (
        <>
            <LogoClient client="" />
        </>
    );
};

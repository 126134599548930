import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import SearchIcon from '@/assets/images/icons/search.png';

import Styles from './Styles.module.scss';
import { DropdownOption } from '@/components/form-controls/dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
    label: string;
    type: string;
    onChange: (value: string) => void;
    options: DropdownOption[];
};

export const Autocomplete: FunctionComponent<Props> = ({ label, type, onChange, options }) => {
    const [suggestions, setSuggestions] = useState<DropdownOption[]>([]);
    const [valueWritten, setValueWritten] = useState<string | undefined>(undefined);
    const [suggestionsBoxWidth, setSuggestionsBoxWidth] = useState<number>(0);

    useEffect(() => {
        if (suggestions.length !== 0) {
            setSuggestionsBoxWidth(
                document.getElementById('autocomplete-control-box')?.offsetWidth || 0
            );
        }
    }, [suggestions]);

    const createSuggestions = useCallback(
        (value: string) => {
            if (value.length < 2) {
                setSuggestions([]);
                return;
            }

            const newSuggestions: DropdownOption[] = options
                .filter((option: DropdownOption) =>
                    option.label.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 4);

            setSuggestions(newSuggestions);
        },
        [options]
    );

    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value: newValue },
            } = event;

            setValueWritten(newValue);
            createSuggestions(newValue);
        },
        [onChange]
    );

    const clickHandler = useCallback(
        (suggestion: DropdownOption) => {
            onChange(suggestion.value);
            setSuggestions([]);
        },
        [onChange]
    );

    const blurHandler = useCallback(() => {
        setSuggestions([]);
    }, []);

    const focusHandler = useCallback(() => {
        createSuggestions(valueWritten || '');
    }, [createSuggestions, valueWritten]);

    return (
        <>
            <div id={'autocomplete-control-box'} className={`form-control-box mb-0`}>
                <label htmlFor="first_name">{label}</label>

                <div className={Styles.InputContainer}>
                    <input
                        type={type}
                        className={'w-100 pe-2'}
                        onChange={changeHandler}
                        value={valueWritten}
                    />

                    <img src={SearchIcon} alt="search-icon" />
                </div>
            </div>

            {suggestions.length !== 0 && (
                <div>
                    <ListGroup
                        variant="flush"
                        className={`border ${Styles.SuggestionBox}`}
                        style={{ width: suggestionsBoxWidth }}
                    >
                        {suggestions.map((suggestion: DropdownOption) => (
                            <ListGroup.Item
                                key={suggestion.value}
                                action={true}
                                onClick={() => clickHandler(suggestion)}
                            >
                                {suggestion.label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            )}
        </>
    );
};

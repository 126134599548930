import React, { useCallback, useEffect, useState } from 'react';
import intersection from 'lodash/intersection';
import { getEvents } from '@/events/api';
import { EventModel } from '@/events/models';
import { Store } from '@/store/types';

type Props = {
    clients: string[];
    campaigns: string[];
    store: Store;
};

export function useEvents({ clients, campaigns, store }: Props) {
    const [states, setStates] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const {
        states: { events: eventsState },
        dispatchers: {
            events: { storeFetchedEvents, storeFilteredEvents },
        },
    } = store;

    const fetchEvents = useCallback(async (): Promise<void> => {
        const newEvents = await getEvents();
        storeFilteredEvents([...newEvents]);
        storeFetchedEvents([...newEvents]);
    }, [clients, campaigns]);

    const filterEvents = useCallback(
        (state: string, language: string, eventType: string) => {
            const filteredEvents: EventModel[] = eventsState.fetchedEvents.filter(
                (event: EventModel) => {
                    const firstCondition = state === 'ALL' || event.venue.state === state;
                    const secondCondition = language === 'ALL' || event.language === language;
                    const thirdCondition = eventType === 'ALL' || event.venue.type === eventType;

                    return firstCondition && secondCondition && thirdCondition;
                }
            );

            storeFilteredEvents(filteredEvents);
        },
        [eventsState.fetchedEvents]
    );

    useEffect(() => {
        if (store.states.events.fetchedEvents.length === 0) {
            fetchEvents().then(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    return {
        states,
        loading,
        filterEvents,
    };
}

import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { Navbar } from './components/navbar';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import { useRouterRoot } from '@/use-router-root';

function App() {
    const router = useRouterRoot();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Welcome | Wider Circle</title>
                <link rel="icon" type="image/x-icon" href="assets/images/favicon.ico" />
            </Helmet>

            <Navbar />

            <Container>
                <RouterProvider router={router} />
            </Container>
        </>
    );
}

export default App;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Store } from '@/store/types';
import { checkInMember, getMembersCheckedIn, getMembersToAttend } from '@/events/api';
import { MemberToAttendModel } from '@/events/models';
import { DateModel } from '@/utilities/dates';

type Props = {
    eventId: string;
    store: Store;
};

export const useEventAttendance = ({ eventId, store }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);

    const {
        states: { events: eventsState },
        dispatchers: {
            events: { storeFetchedMembersToAttend, storeFetchedMembersCheckedIn },
        },
    } = store;

    const fetchMembersToAttend = useCallback(async () => {
        const newMembersToAttend = await getMembersToAttend(eventId);
        storeFetchedMembersToAttend(newMembersToAttend);
    }, [eventId, storeFetchedMembersToAttend]);

    const fetchMembersCheckedIn = useCallback(async () => {
        const newMembersCheckedIn = await getMembersCheckedIn(eventId);
        storeFetchedMembersCheckedIn(newMembersCheckedIn);
    }, [eventId]);

    const fetchData = useCallback(async () => {
        const promises = [
            eventsState.membersToAttend.length === 0 ? fetchMembersToAttend() : Promise.resolve(),
            fetchMembersCheckedIn(),
        ];
        await Promise.all(promises);
        setLoading(false);
    }, []);

    const membersAlreadyPresent: MemberToAttendModel[] = useMemo(() => {
        if (!eventId || loading) {
            return [];
        }

        const idsOfMembersAlreadyCheckedIn: string[] = store.states.events.membersCheckedIn.map(
            (member) => member.memberId
        );

        return store.states.events.membersToAttend.filter((member) =>
            idsOfMembersAlreadyCheckedIn.includes(member.memberId)
        );
    }, [
        store.states.events.membersCheckedIn.length,
        store.states.events.membersToAttend.length,
        loading,
        eventId,
    ]);

    const membersNotPresentYet: MemberToAttendModel[] = useMemo(() => {
        if (!eventId || loading) {
            return [];
        }

        const idsOfMembersAlreadyCheckedIn: string[] = store.states.events.membersCheckedIn.map(
            (member) => member.memberId
        );

        return store.states.events.membersToAttend.filter(
            (member) => !idsOfMembersAlreadyCheckedIn.includes(member.memberId)
        );
    }, [
        store.states.events.membersCheckedIn.length,
        store.states.events.membersToAttend.length,
        loading,
        eventId,
    ]);

    const checkInMemberToEvent = useCallback(async (member: MemberToAttendModel) => {
        setLoading(true);

        await checkInMember({
            memberId: member.memberId,
            eventId,
            checkedInDate: new DateModel().toMilliseconds(),
        });
        console.log('INSIDE 1');

        await fetchMembersCheckedIn();
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return {
        loading,
        membersAlreadyPresent,
        membersNotPresentYet,
        checkInMemberToEvent,
    };
};

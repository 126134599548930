import React from 'react';
import { Store } from '@/store/types';
import { useEventsReducer } from '@/events/reducer';

export const useStore = (): Store => {
    const {
        eventsState,
        storeFetchedEvents,
        storeFilteredEvents,
        selectEvent,
        storeFetchedMembersToAttend,
        storeFetchedMembersCheckedIn,
    } = useEventsReducer();
    return {
        states: {
            events: eventsState,
        },
        dispatchers: {
            events: {
                storeFetchedEvents,
                storeFilteredEvents,
                selectEvent,
                storeFetchedMembersToAttend,
                storeFetchedMembersCheckedIn,
            },
        },
    };
};

import {MemberToAttendModel, EventModel, MemberCheckedInModel} from '@/events/models';

export interface EventsReducerAction {
    type: EventsActions;
    payload: {
        events?: EventModel[];
        event?: EventModel;
        membersToAttend?: MemberToAttendModel[];
        membersCheckedIn?: MemberCheckedInModel[];
    };
}

export enum EventsActions {
    StoreFetchedEvents = 'StoreFetchedEvents',
    StoreFilteredEvents = 'StoreFilteredEvents',
    SelectEvent = 'SelectEvent',
    StoreFetchedMembersToAttend = 'StoreFetchedMembersToAttend',
    StoreFetchedMembersCheckedIn = 'StoreFetchedMembersCheckedIn',
}

export const storeFetchedEvents = (events: EventModel[]): EventsReducerAction => {
    return {
        type: EventsActions.StoreFetchedEvents,
        payload: {
            events,
        },
    };
};

export const storeFilteredEvents = (events: EventModel[]): EventsReducerAction => {
    return {
        type: EventsActions.StoreFilteredEvents,
        payload: {
            events,
        },
    };
};

export const selectEvent = (event: EventModel): EventsReducerAction => {
    return {
        type: EventsActions.SelectEvent,
        payload: {
            event,
        },
    };
};

export const storeFetchedMembersToAttend = (
    membersToAttend: MemberToAttendModel[]
): EventsReducerAction => {
    return {
        type: EventsActions.StoreFetchedMembersToAttend,
        payload: {
            membersToAttend,
        },
    };
};

export const storeFetchedMembersCheckedIn = (
    membersCheckedIn: MemberCheckedInModel[]
): EventsReducerAction => {
    return {
        type: EventsActions.StoreFetchedMembersCheckedIn,
        payload: {
            membersCheckedIn,
        },
    };
};

import { EventLanguage, EventModel, VenueType } from '@/events/models';
import { DateModel } from '@/utilities/dates';

import EventImage0 from '@/assets/images/events/0.png';
import EventImage1 from '@/assets/images/events/1.png';
import EventImage2 from '@/assets/images/events/2.png';
import EventImage3 from '@/assets/images/events/3.png';
import EventImage4 from '@/assets/images/events/4.png';
import EventImage5 from '@/assets/images/events/5.png';
import EventImage6 from '@/assets/images/events/6.png';
import EventImage7 from '@/assets/images/events/7.png';
import EventImage8 from '@/assets/images/events/8.png';
import EventImage9 from '@/assets/images/events/9.png';
import EventImage10 from '@/assets/images/events/10.png';
import EventImage11 from '@/assets/images/events/11.png';
import EventImage12 from '@/assets/images/events/12.png';
import EventImage13 from '@/assets/images/events/13.png';
import EventImage14 from '@/assets/images/events/14.png';
import EventImage15 from '@/assets/images/events/15.png';
import EventImage16 from '@/assets/images/events/16.png';
import EventImage17 from '@/assets/images/events/17.png';
import EventImage18 from '@/assets/images/events/18.png';
import EventImage19 from '@/assets/images/events/19.png';
import { EventApi } from '@/events/api/types';

const getImage = (index: number): string => {
    switch (index % 20) {
        case 0:
            return EventImage0;
        case 1:
            return EventImage1;
        case 2:
            return EventImage2;
        case 3:
            return EventImage3;
        case 4:
            return EventImage4;
        case 5:
            return EventImage5;
        case 6:
            return EventImage6;
        case 7:
            return EventImage7;
        case 8:
            return EventImage8;
        case 9:
            return EventImage9;
        case 10:
            return EventImage10;
        case 11:
            return EventImage11;
        case 12:
            return EventImage12;
        case 13:
            return EventImage13;
        case 14:
            return EventImage14;
        case 15:
            return EventImage15;
        case 16:
            return EventImage16;
        case 17:
            return EventImage17;
        case 18:
            return EventImage18;
        case 19:
            return EventImage19;
        default:
            return EventImage0;
    }
};

export const eventApiToEventModel = (eventApi: EventApi, index: number): EventModel => {
    const startDate: DateModel = new DateModel();
    startDate.setDateFromMilliseconds(eventApi.event_start);

    const endDate: DateModel = new DateModel();
    endDate.setDateFromMilliseconds(eventApi.event_start);

    return {
        id: eventApi.event_id,
        name: eventApi.friendly_name,
        startDate,
        endDate,
        language:
            eventApi.language?.toLowerCase() === 'spanish' ? EventLanguage.ES : EventLanguage.EN,
        clients: eventApi.client ? eventApi.client.map((client) => client.toLowerCase()) : [],
        campaigns: eventApi.topic ? eventApi.topic.map((topic) => topic.toLowerCase()) : [],
        imageUrl: getImage(index),
        venue: {
            name: eventApi.venue_name || '',
            addressLine1: eventApi.address1 || '',
            addressLine2: eventApi.address2 || '',
            city: eventApi.venue_city || '',
            state: eventApi.venue_state || '',
            zipcode: eventApi.venue_zip || '',
            phoneNumber: eventApi.venue_phone_number || '',
            type: eventApi.venue_type.toLowerCase().includes('virtual')
                ? VenueType.VIRTUAL
                : VenueType.IN_PERSON,
        },
    };
};

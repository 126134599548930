import { HttpClient } from './http-client';

const httpClientForSalesforce = new HttpClient({
    baseUrl: process.env.REACT_APP_API_URL || '',
    headers: { secret: process.env.REACT_APP_API_SECRET || '', 'access-control-allow-origin': '*' },
});

const httpClientForNodeService = new HttpClient({
    baseUrl: process.env.REACT_APP_NODE_API_URL || '',
    headers: {},
});

export { httpClientForSalesforce, httpClientForNodeService };

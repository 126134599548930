import React, { FunctionComponent, useCallback, MouseEvent, ReactNode } from 'react';
import BootstrapButton from 'react-bootstrap/esm/Button';
import Styles from './Styles.module.scss';

export enum ButtonType {
    PRIMARY,
    LIGHT,
    PRIMARY_BLUE,
    LIGHT_BLUE,
}

type Props = {
    type: ButtonType;
    onClick: () => void;
    children?: ReactNode | ReactNode[];
    customClassName?: string;
    disabled?: boolean;
};

export const Button: FunctionComponent<Props> = ({
    type,
    onClick,
    children,
    customClassName,
    disabled,
}) => {
    const clickHandler = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onClick();
        },
        [onClick]
    );

    switch (type) {
        case ButtonType.LIGHT:
            return (
                <BootstrapButton
                    variant="outline-dark"
                    onClick={clickHandler}
                    className={`${Styles.ButtonLight} ${customClassName}`}
                    disabled={disabled}
                >
                    {children}
                </BootstrapButton>
            );

        case ButtonType.LIGHT_BLUE:
            return (
                <BootstrapButton
                    variant="outline-dark"
                    onClick={clickHandler}
                    className={`${Styles.ButtonLightBlue} ${customClassName}`}
                    disabled={disabled}
                >
                    {children}
                </BootstrapButton>
            );

        case ButtonType.PRIMARY:
            return (
                <BootstrapButton
                    onClick={clickHandler}
                    className={`${Styles.ButtonPrimary} ${customClassName}`}
                    disabled={disabled}
                >
                    {children}
                </BootstrapButton>
            );

        case ButtonType.PRIMARY_BLUE:
            return (
                <BootstrapButton
                    onClick={clickHandler}
                    className={`${Styles.ButtonPrimaryBlue} ${customClassName}`}
                    disabled={disabled}
                >
                    {children}
                </BootstrapButton>
            );

        default:
            return null;
    }
};

import { MemberToAttendModel, EventModel, MemberCheckedInModel } from '@/events/models';
import { httpClientForNodeService, httpClientForSalesforce } from '@/services/http-client';
import { eventApiToEventModel } from '@/events/mappers/events-mapper';
import {
    MemberToAttendApi,
    ContactMatchingBody,
    EventApi,
    EventRegistrationBody,
    MemberCheckedInApi,
} from '@/events/api/types';
import { memberToAttendApiToMemberToAttendModel } from '@/events/mappers/members-to-attend-mapper';
import { memberCheckedInApiToMemberCheckedInModel } from '@/events/mappers/members-checked-in-mapper';

interface EventsListResponse {
    events: EventApi[];
}

interface MembersToAttendListResponse {
    members: MemberToAttendApi[];
}

interface MatchContactResponse {
    contact_ids: string[];
}

export const getEvents = async (): Promise<EventModel[]> => {
    const response = await httpClientForNodeService.requestFull<EventsListResponse>({
        method: 'GET',
        url: '/selfserve',
    });

    return response.data.events.map(eventApiToEventModel);
};

export const matchContact = async (body: ContactMatchingBody): Promise<string | undefined> => {
    const response = await httpClientForSalesforce.requestFull<MatchContactResponse>({
        method: 'POST',
        url: '/selfserve/match',
        data: body,
    });

    return response.status === 200 && response.data.contact_ids.length > 0
        ? response.data.contact_ids[0]
        : undefined;
};

export const registerForEvent = async (body: EventRegistrationBody): Promise<void> => {
    await httpClientForSalesforce.requestFull({
        method: 'POST',
        url: '/selfserve',
        data: body,
    });
};

export const getMembersToAttend = async (eventId: string): Promise<MemberToAttendModel[]> => {
    const response = await httpClientForNodeService.requestFull<MembersToAttendListResponse>({
        method: 'GET',
        url: `/easyreg?event_id=${eventId}`,
    });

    return response.data.members.map(memberToAttendApiToMemberToAttendModel);
};

export const getMembersCheckedIn = async (eventId: string): Promise<MemberCheckedInModel[]> => {
    const response = await httpClientForNodeService.requestFull<MemberCheckedInApi[]>({
        method: 'GET',
        url: `attendees/${eventId}`,
    });

    return response.data.map(memberCheckedInApiToMemberCheckedInModel);
};

export const checkInMember = async (information: MemberCheckedInApi): Promise<void> => {
    await httpClientForNodeService.requestFull({
        method: 'POST',
        url: '/',
        data: information,
    });
};

import { MemberToAttendModel } from '@/events/models';
import { MemberToAttendApi } from '@/events/api/types';

export const memberToAttendApiToMemberToAttendModel = (
    attendee: MemberToAttendApi
): MemberToAttendModel => {
    return {
        eventAttendanceId: attendee.ea_id,
        memberId: attendee.member_id,
        firstName: attendee.first_name,
        lastName: attendee.last_name,
        name: attendee.name,
        disposition: attendee.disposition,
    };
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MembersSearch } from '@/events/pages/event-attendance/members-search';
import { useQueryParams } from '@/utilities/use-query-params';
import Alert from 'react-bootstrap/Alert';
import { MembersWhoHaveCheckedInTheEvent } from '@/events/pages/event-attendance/members-who-have-checked-in-the-event';
import Styles from './styles.module.scss';
import { MemberFound } from '@/events/pages/event-attendance/member-found';
import { useEvents } from '@/events/pages/use-events';
import { Store } from '@/store/types';
import { LogoSpinner } from '@/components/spinner/logo-spinner';
import { EventModel, MemberToAttendModel } from '@/events/models';
import { useEventAttendance } from '@/events/pages/use-event-attendance';

type Props = {
    store: Store;
};

export const EventAttendance: FunctionComponent<Props> = ({ store }) => {
    const { getQueryParameter } = useQueryParams();
    const [memberToRegister, setMemberToRegister] = useState<MemberToAttendModel | undefined>(
        undefined
    );

    const { loading } = useEvents({
        clients: ['aetna'],
        campaigns: [],
        store,
    });

    const eventId: string = useMemo(() => {
        return getQueryParameter('event') || '';
    }, [getQueryParameter]);

    const {
        loading: loadingAttendees,
        membersAlreadyPresent,
        membersNotPresentYet,
        checkInMemberToEvent,
    } = useEventAttendance({
        eventId,
        store,
    });

    const memberId: string = useMemo(() => {
        return getQueryParameter('member') || '';
    }, [getQueryParameter]);

    const event = useMemo<EventModel | undefined>(() => {
        if (!eventId || loading) {
            return undefined;
        }

        return store.states.events.fetchedEvents.find((eventFetch: EventModel) => {
            return eventFetch.id === eventId;
        });
    }, [eventId, loading, store.states.events.fetchedEvents]);

    const registerMember = useCallback((member: MemberToAttendModel) => {
        setMemberToRegister(member);
    }, []);

    const backToListHandler = useCallback(() => {
        setMemberToRegister(undefined);
    }, []);

    const checkInHandler = useCallback(async () => {
        await checkInMemberToEvent(memberToRegister as MemberToAttendModel);
    }, [memberToRegister]);

    useEffect(() => {
        if (memberId) {
            const memberFound = store.states.events.membersToAttend.find(
                (member) => member.memberId === memberId
            );

            if (memberFound) {
                setMemberToRegister(memberFound);
            }
        }
    }, [memberId]);

    if (loading || loadingAttendees) {
        return <LogoSpinner />;
    }

    if (!event) {
        return (
            <Row className={'mx-0 py-4'}>
                <Col className={'px-2 py-4'}>
                    <Alert variant={'danger'}>
                        We could not found an event going on at the moment
                    </Alert>
                </Col>
            </Row>
        );
    }

    return (
        <Row className={'mx-0 pt-4'}>
            <Col
                xl={4}
                lg={4}
                md={2}
                className={'d-xl-block d-lg-block d-md-block d-none-sm d-none'}
            />

            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {!memberToRegister && (
                    <>
                        <Row className={'mx-0 py-4'}>
                            <Col>
                                <span className={Styles.Title}>Check in for: {event.name}</span>
                            </Col>
                        </Row>

                        <MembersSearch
                            onMemberSelected={registerMember}
                            members={membersNotPresentYet}
                        />

                        <MembersWhoHaveCheckedInTheEvent members={membersAlreadyPresent} />
                    </>
                )}

                {memberToRegister && (
                    <MemberFound
                        member={memberToRegister}
                        event={event}
                        onBackToList={backToListHandler}
                        onCheckIn={checkInHandler}
                        comesFromQueryParameters={memberId !== ''}
                        isRegistered={
                            membersAlreadyPresent.find(
                                (member) => member.memberId === memberToRegister?.memberId
                            ) !== undefined
                        }
                    />
                )}
            </Col>

            <Col
                xl={4}
                lg={4}
                md={2}
                className={'d-xl-block d-lg-block d-md-block d-none-sm d-none'}
            />
        </Row>
    );
};

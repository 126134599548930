import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Autocomplete } from '@/components/form-controls/autocomplete';
import { DropdownOption } from '@/components/form-controls/dropdown';
import Styles from './styles.module.scss';
import { MemberToAttendModel } from '@/events/models';

type Props = {
    onMemberSelected: (member: MemberToAttendModel) => void;
    members: MemberToAttendModel[];
};

export const MembersSearch: FunctionComponent<Props> = ({ onMemberSelected, members }) => {
    const options: DropdownOption[] = useMemo(() => {
        return members.map((member: MemberToAttendModel) => ({
            value: member.memberId,
            label: `${member.firstName} ${member.lastName}`,
        }));
    }, [members.length]);

    const memberSelectedHandler = useCallback(
        (memberId: string) => {
            const memberFound: MemberToAttendModel = members.find(
                (member) => member.memberId === memberId
            ) as MemberToAttendModel;
            onMemberSelected(memberFound);
        },
        [onMemberSelected]
    );

    return (
        <>
            <Row className={`mx-0 pb-2`}>
                <Col>
                    <Autocomplete
                        label={'Search By Name'}
                        type={'text'}
                        onChange={memberSelectedHandler}
                        options={options}
                    />
                </Col>
            </Row>

            <Row className={`mx-0 ${Styles.Subtitle}`}>
                <Col>
                    <span>Search for your name to check in</span>
                </Col>
            </Row>
        </>
    );
};

import { DateModel } from '@/utilities/dates';

export enum VenueType {
    VIRTUAL = 'virtual',
    IN_PERSON = 'person',
}

export enum EventLanguage {
    EN = 'EN',
    ES = 'ES',
}

export interface VenueModel {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: string;
    phoneNumber?: string;
    type: VenueType;
}

export interface EventModel {
    id: string;
    name: string;
    startDate: DateModel;
    endDate: DateModel;
    language: EventLanguage;
    clients: string[];
    campaigns: string[];
    imageUrl: string;
    venue: VenueModel;
}

export interface MemberToAttendModel {
    eventAttendanceId: string;
    memberId: string;
    firstName: string;
    lastName: string;
    name: string;
    disposition: string;
}

export interface MemberCheckedInModel {
    id: string;
    memberId: string;
    checkedInDate: DateModel;
}

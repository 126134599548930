import React, { FunctionComponent, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from './styles.module.scss';

import AetnaLogo from '@/assets/images/clients/aetna-logo.png';
import Logo from '@/assets/images/logo.png';

type Props = {
    client?: string;
};

export const LogoClient: FunctionComponent<Props> = ({ client }) => {
    const companyLogoAlignmentClass: string = useMemo(() => {
        return !client ? 'justify-content-center' : 'justify-content-start';
    }, [client]);

    return (
        <Row className={`mx-0 pt-2 ${Styles.Container}`}>
            <Col className={`d-flex align-items-center ${companyLogoAlignmentClass}`}>
                <img src={Logo} alt="wider-circle-logo" />
            </Col>

            {client && (
                <Col className="ps-0 d-flex justify-content-end align-items-center">
                    {client === 'aetna' && (
                        <img
                            src={AetnaLogo}
                            alt="wider-circle-logo"
                            className={Styles.ClientLogo}
                        />
                    )}
                </Col>
            )}
        </Row>
    );
};

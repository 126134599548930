import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

export class DateModel {
    private _date: dayjs.Dayjs;

    constructor() {
        this._date = dayjs();
    }

    public setDateFromMilliseconds(milliseconds: number) {
        this._date = dayjs(milliseconds);
    }

    public format(pattern: string): string {
        return this._date.format(pattern);
    }

    public addSeconds(seconds: number): Date {
        return this._date.add(seconds, 'second').toDate();
    }

    public toMilliseconds(): number {
        return this._date.valueOf();
    }
}

import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
// import { AllEventsPage } from '@/events/pages/all-events';
// import { EventRegistration } from '@/events/pages/event-registration';
import { useStore } from '@/store';
import { EventAttendance } from '@/events/pages/event-attendance';

export const useRouterRoot = () => {
    const store = useStore();

    return createBrowserRouter([
        {
            path: '/',
            element: <EventAttendance store={store} />,
        },
    ]);
};

import { MemberCheckedInApi } from '@/events/api/types';
import { MemberCheckedInModel } from '@/events/models';
import { DateModel } from '@/utilities/dates';

export const memberCheckedInApiToMemberCheckedInModel = (
    member: MemberCheckedInApi
): MemberCheckedInModel => {
    const checkedInDate = new DateModel();
    checkedInDate.setDateFromMilliseconds(member.checkedInDate);

    return {
        id: member._id || '',
        memberId: member.memberId,
        checkedInDate,
    };
};

import { useReducer } from 'react';
import { MemberToAttendModel, EventModel, MemberCheckedInModel } from '@/events/models';

import {
    EventsActions,
    EventsReducerAction,
    selectEvent,
    storeFetchedMembersToAttend,
    storeFetchedEvents,
    storeFilteredEvents,
    storeFetchedMembersCheckedIn,
} from '@/events/reducer/actions';

import { EventsState, initialEventsState } from '@/events/reducer/state';

export interface EventsDispatchers {
    storeFetchedEvents: (events: EventModel[]) => void;
    storeFilteredEvents: (events: EventModel[]) => void;
    selectEvent: (event: EventModel) => void;
    storeFetchedMembersToAttend: (membersToAttend: MemberToAttendModel[]) => void;
    storeFetchedMembersCheckedIn: (membersCheckedIn: MemberCheckedInModel[]) => void;
}

const reducer = (state: EventsState, action: EventsReducerAction): EventsState => {
    switch (action.type) {
        case EventsActions.StoreFetchedEvents:
            return {
                ...state,
                fetchedEvents: action.payload.events || [],
            };

        case EventsActions.StoreFilteredEvents:
            return {
                ...state,
                filteredEvents: action.payload.events || [],
            };

        case EventsActions.SelectEvent:
            return {
                ...state,
                selectedEvent: action.payload.event,
            };

        case EventsActions.StoreFetchedMembersToAttend:
            return {
                ...state,
                membersToAttend: action.payload.membersToAttend || [],
            };

        case EventsActions.StoreFetchedMembersCheckedIn:
            return {
                ...state,
                membersCheckedIn: action.payload.membersCheckedIn || [],
            };

        default:
            return state;
    }
};

export const useEventsReducer = () => {
    const [eventsState, dispatch] = useReducer(reducer, initialEventsState);

    return {
        eventsState,
        storeFetchedEvents: (events: EventModel[]): void => dispatch(storeFetchedEvents(events)),
        storeFilteredEvents: (events: EventModel[]): void => dispatch(storeFilteredEvents(events)),
        selectEvent: (event: EventModel): void => dispatch(selectEvent(event)),
        storeFetchedMembersToAttend: (eventAttendances: MemberToAttendModel[]): void =>
            dispatch(storeFetchedMembersToAttend(eventAttendances)),
        storeFetchedMembersCheckedIn: (membersCheckedIn: MemberCheckedInModel[]): void =>
            dispatch(storeFetchedMembersCheckedIn(membersCheckedIn)),
    };
};

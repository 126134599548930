import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from './styles.module.scss';
import { MemberToAttendModel } from '@/events/models';

type Props = {
    members: MemberToAttendModel[];
};

export const MembersWhoHaveCheckedInTheEvent: FunctionComponent<Props> = ({ members }) => {
    return (
        <>
            <Row className={'mx-0 pt-4 pb-2'}>
                <Col className={'pt-4'}>
                    <span className={Styles.Title}>People who have checked in</span>
                </Col>
            </Row>

            {members.map((member: MemberToAttendModel, index: number) => (
                <Row key={member.memberId} className={'mx-0 pb-0'}>
                    <Col className={`px-3 py-0 ${Styles.RegisteredMember}`}>
                        <div>
                            {index + 1}. {`${member.firstName} ${member.lastName}`}
                        </div>
                    </Col>
                </Row>
            ))}
        </>
    );
};

import React, { FunctionComponent } from 'react';
import { Member } from '@/members/models';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from './styles.module.scss';
import { Button, ButtonType } from '@/components/form-controls/button';
import { EventModel, MemberToAttendModel } from '@/events/models';
import CheckXl from '@/assets/images/icons/check-xl.png';

type Props = {
    member: MemberToAttendModel;
    event: EventModel;
    onBackToList: () => void;
    onCheckIn: () => Promise<void>;
    comesFromQueryParameters: boolean;
    isRegistered: boolean;
};

export const MemberFound: FunctionComponent<Props> = ({
    member,
    event,
    onBackToList,
    onCheckIn,
    comesFromQueryParameters,
    isRegistered,
}) => {
    return (
        <>
            {isRegistered && (
                <Row className={'mx-0 pb-4'}>
                    <Col className={`d-flex justify-content-center align-items-center`}>
                        <img src={CheckXl} className={Styles.SuccessImage} />
                    </Col>
                </Row>
            )}

            <Row className={'mx-0 pb-4'}>
                <Col className={`d-flex justify-content-center ${Styles.Title}`}>
                    {isRegistered ? `Thank you, ${member.firstName}!` : `Hi ${member.firstName}!`}
                </Col>
            </Row>

            <Row className={'mx-0 pb-4'}>
                <Col className={``} xs={12}>
                    <span>
                        {isRegistered ? `You are checked in for:` : `This is check in for:`}
                    </span>
                </Col>

                <Col className={``} xs={12}>
                    <span>{event.startDate.format('LT')}</span>
                </Col>

                <Col className={``} xs={12}>
                    <span>{event.name}</span>
                </Col>

                <Col className={``} xs={12}>
                    <span>{event.venue.name}</span>
                </Col>

                <Col className={``} xs={12}>
                    <span>
                        {event.venue.city}, {event.venue.state} {event.venue.zipcode}
                    </span>
                </Col>
            </Row>

            {!isRegistered && (
                <Row className={'mx-0 pt-4'}>
                    <Col
                        xl={comesFromQueryParameters ? 12 : 6}
                        lg={comesFromQueryParameters ? 12 : 6}
                        md={comesFromQueryParameters ? 12 : 6}
                        sm={12}
                        xs={12}
                        className={'pb-2 px-'}
                    >
                        <Button
                            type={ButtonType.PRIMARY_BLUE}
                            onClick={onCheckIn}
                            customClassName={`px-4 py-2 w-100`}
                        >
                            I am here
                        </Button>
                    </Col>

                    {!comesFromQueryParameters && (
                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="px-">
                            <Button
                                type={ButtonType.LIGHT_BLUE}
                                onClick={onBackToList}
                                customClassName={`px-4 py-2 w-100`}
                            >
                                Back to list
                            </Button>
                        </Col>
                    )}
                </Row>
            )}

            {isRegistered && !comesFromQueryParameters && (
                <Row className={'mx-0 pt-4'}>
                    <Col className="px-">
                        <Button
                            type={ButtonType.LIGHT_BLUE}
                            onClick={onBackToList}
                            customClassName={`px-4 py-2 w-100`}
                        >
                            Back to list
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};
